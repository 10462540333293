




































































































































































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import to from 'await-to-js';
import axios from 'axios';
// @ts-ignore
import VueUploadMultipleImage from 'vue-upload-multiple-image';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import firebase from 'firebase/app';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import { bloqifyFirestore, bloqifyStorage } from '@/boot/firebase';
import { convertUTCToLocalDate, convertLocalDateToUTC } from '@/filters/date';
import { Asset } from '@/models/assets/Asset';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormSelect from '@/components/common/form-elements/FormSelect.vue';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import FormEditor from '@/components/common/form-elements/FormEditor.vue';
import singleDocumentQuery from '@/mixins/singleDocumentQuery';

interface Valuation {
  propertyValue: number | null,
  purchaseCost: number | null,
  tax: number | null,
  createdDateTime: firebase.firestore.Timestamp | null,
}

@Component({
  components: {
    VueUploadMultipleImage,
    FormDatePicker,
    FormEditor,
    FormInput,
    FormSelect,
    ValidationObserver,
    ValidationProvider,
    FormInvalidMessage,
  },
  mixins: [
    singleDocumentQuery({
      ref: bloqifyFirestore.collection('assets'),
      stateSlice: 'boundAsset',
      idName: 'assetId',
    }),
  ],
})
export default class CreateAssets extends Vue {
  FormIcons = FormIcons;

  // Financial constants (specific for Pebbles)
  sharePrice = 50;
  euroMin = 250;
  // Initial value of the emmision cost is 2% (editable and will be overwritten by data coming from the database)
  emissionCost = 2;
  excessEmissionPerc: number | null = null;
  excessEmissionThreshold: number | null = null;

  name: string = '';
  street: string = '';
  houseNumber: string = '';
  postalCode: string = '';
  city: string = '';
  country: string = 'Netherlands';
  valuation: Valuation[] = [
    {
      propertyValue: null,
      purchaseCost: null,
      tax: null,
      createdDateTime: null,
    },
  ];
  hpi: [number | null, number | null, number | null] = [null, null, null];
  grossRent: number | null = null;
  serviceCharges: number | null = null;
  costs: number | null = null;
  investmentCase: any = '';
  propertyDetails: any = '';
  premium: boolean = false;
  published: boolean = false;
  dividendsFormat: { contents: [string, number | null | string] }[] = [{ contents: ['', null] }];
  images: any[] = [];
  floorPlanImages: any[] = [];
  prospectus: any[] = [];
  brochure: any[] = [];
  location: { lat: number, lng: number } = { lat: 0, lng: 0 };
  mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
  };
  imagesLoading = {
    images: false,
    floorPlanImages: false,
  };
  startDateTime: Date | null = null;
  endDateTime: Date | null = null;
  returnsAfterEnd: number | null = null;
  fixedDividends: boolean | null = null;
  otherChanges: boolean = false;
  saveButtonTitle: string = '';

  @Action createAsset!: Function;
  @Action updateAsset!: Function;
  @Action handlePublishAssetById!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass('boundAsset') asset!: State['boundAsset'];
  @StateClass('asset') operationalAsset!: State['asset'];

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Watch('operationalAsset.error')
  onNewAssetError(newError?: Error): void {
    if (newError) {
      this.addToastMessage({
        text: newError.message,
        type: 'danger',
      });
    }
  }

  @Watch('operationalAsset.status')
  async onOperationalAssetStatusChange(newStatus: DataContainerStatus): Promise<void> {
    if ((this.operationalAsset?.operation === 'createAsset' || this.operationalAsset?.operation === 'updateAsset')
      && newStatus === DataContainerStatus.Success) {
      // Redirect if we were in create page
      if (this.$route.fullPath !== `/create-modify-asset/${this.operationalAsset.payload.id}`) {
        this.$router.push(`/create-modify-asset/${this.operationalAsset.payload.id}`);
      }

      this.addToastMessage({
        text: 'Fund correctly saved.',
        type: 'success',
      });
      // Resetting form validation
      this.otherChanges = false;
      this.form.reset();
    }
  }

  @Watch('asset')
  async onAssetStatusChange(newAsset: Asset): Promise<void> {
    if (newAsset) {
      const imgArrayNames = ['images', 'floorPlanImages'];
      const fileArrayNames = ['prospectus', 'brochure'];
      const allArrayNames = [...imgArrayNames, ...fileArrayNames];

      const asset = newAsset;

      // Here we are setting the received data into the form fields
      Object.keys(asset).forEach((key): void => {
        if (!allArrayNames.some((arrayName): boolean => arrayName === key)) {
          // Assigining dynamically all the asset properties to the form
          if (key !== 'investmentCase' && key !== 'propertyDetails' && key !== 'dividendsFormat'
            && key !== 'startDateTime' && key !== 'endDateTime' && key !== 'totalValueEuro') {
            this[key] = asset[key];
          }
        } else {
          // Resetting file arrays
          this[key] = [];
        }
      });

      ['startDateTime', 'endDateTime'].forEach((dateKey): void => {
        // From unix format to Date format
        this[dateKey] = (asset[dateKey] && convertUTCToLocalDate(asset[dateKey])) || null;
      });

      // // Cloning the array to avoid the Vuex mutation warning
      // this.hpi = asset.hpi ? ([...asset.hpi] as [number, number, number]) : [null, null, null];
      // // @ts-ignore
      // this.valuation = [...asset.valuation].map((val) => ({ ...val, createdDateTime: new Date(val.createdDateTime._seconds * 1000) }));

      // To avoid mutations at editing
      this.dividendsFormat = [
        ...asset.dividendsFormat.map(
          (contentObject): {
            contents: [string, number];
          } => ({ ...contentObject }),
        ),
      ];

      // Editor content
      this.investmentCase = asset.investmentCase;
      this.propertyDetails = asset.propertyDetails;

      // Images
      imgArrayNames.forEach(async (key): Promise<void> => {
        let imgLoadedCount = 0;

        await Promise.all(asset[key].map(async (img): Promise<void> => {
          // Loader ON
          this.imagesLoading[key] = true;

          const storageRef = bloqifyStorage.ref().child(img);
          const [getDownloadUrlError, fileUrl] = await to(storageRef.getDownloadURL());
          if (getDownloadUrlError) {
            this.addToastMessage({
              text: getDownloadUrlError.message || 'There was an error retrieving the images.',
              type: 'danger',
            });
            throw getDownloadUrlError;
          }

          const [getMetadataError, metadata] = await to(storageRef.getMetadata());
          if (getMetadataError) {
            this.addToastMessage({
              text: getMetadataError.message || 'There was an error retrieving the images.',
              type: 'danger',
            });
            throw getMetadataError;
          }

          const { contentType, name: fileName } = metadata;

          const [getFileError, response] = await to(axios.get(
            fileUrl,
            {
              responseType: 'arraybuffer',
            },
          ));
          if (getFileError) {
            this.addToastMessage({
              text: getFileError.message || 'There was an error retrieving the images.',
              type: 'danger',
            });
            throw getFileError;
          }

          const responseBlob = response!.data as Blob;
          const reader = new FileReader();

          reader.readAsDataURL(new Blob([responseBlob], { type: contentType }));
          reader.onload = (e) => {
            const length = this[key].length;
            // This object type is the one required by the component used for images
            this[key].push({
              name: fileName,
              path: reader.result,
              highlight: !length ? 1 : 0,
              default: !length ? 1 : 0,
              file: new File([responseBlob], fileName, { type: contentType }),
            });

            imgLoadedCount++;

            if (imgLoadedCount === asset[key].length) {
              // Loader OFF
              this.imagesLoading[key] = false;
            }
          };
        }));
      });

      // Files (pdfs)
      fileArrayNames.forEach(async (key): Promise<void> => {
        await Promise.all(asset[key].map(async (pdf): Promise<void> => {
          const contentType = 'application/pdf';
          const storageRef = bloqifyStorage.ref().child(pdf);
          const [getError, fileUrl] = await to(storageRef.getDownloadURL());
          if (getError) {
            this.addToastMessage({
              text: getError.message || 'There was an error retrieving the files.',
              type: 'danger',
            });
            throw getError;
          }

          const [getFileError, response] = await to(axios.get(
            fileUrl,
            {
              responseType: 'arraybuffer',
            },
          ));
          if (getFileError) {
            this.addToastMessage({
              text: getFileError.message || 'There was an error retrieving the images.',
              type: 'danger',
            });
            throw getFileError;
          }

          this[key].push({ name: pdf, file: new File([response!.data], pdf, { type: contentType }) });
        }));
      });
    }
  }

  @Watch('addressBuilt')
  async onAddressChange(newAddress: string, oldAddress: string): Promise<void> {
    if (this.city && this.city.length > 2) {
      // @ts-ignore
      const [mapsApiError, mapsApi] = await to(this.$gmapApiPromiseLazy());
      if (mapsApiError) {
        return;
      }
      // @ts-ignore
      const geocoder = new mapsApi.maps.Geocoder();
      geocoder.geocode({ address: newAddress }, (results, status): void => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          this.location = {
            lat: location.lat instanceof Function ? location.lat() : location.lat,
            lng: location.lng instanceof Function ? location.lng() : location.lng,
          };
        }
      });
    }
  }

  @Watch('premium') onPremiumChange(newPremium: boolean) {
    // reset dividend data
    this.dividendsFormat = [{ contents: ['', 0] }];
    this.returnsAfterEnd = null;
    this.fixedDividends = null;
  }

  get loadingAsset(): boolean {
    return this.operationalAsset?.status === DataContainerStatus.Processing;
  }

  get URL(): Function {
    // @ts-ignore
    return window.URL || window.webkitURL;
  }

  // Computing the totalValueEuro field based on other financial input
  get totalValueEuro(): number {
    const last = this.valuation.length - 1;
    const { propertyValue, purchaseCost, tax } = this.valuation[last];

    return (Number(propertyValue) || 0) + (Number(purchaseCost) || 0) - (Number(tax) || 0);
  }

  get evaluatedSharePrice(): number {
    // For the first evaluation the share price is fixed
    if (this.valuation.length === 1) {
      return this.sharePrice;
    }

    return this.asset ? Math.round((this.totalValueEuro / this.asset.totalValueShares) * 100) / 100 : this.sharePrice;
  }

  /**
   * Returns the value needed to be added to the valuation to get an integer number of shares
   * at the first evaluation
   */
  get deltaValue(): number {
    // Only care about the difference for the first evaluation
    if (this.valuation.length > 1) {
      return 0;
    }

    const delta = this.totalValueEuro % this.sharePrice;
    return delta === 0 ? 0 : this.sharePrice - delta;
  }

  // Computing the dividend yield based on other financial input (rounded to 2 decimals)
  get dividendYield(): number {
    return (this.totalValueEuro != null && this.grossRent != null && this.serviceCharges != null && this.costs != null)
      ? Math.round(((this.grossRent - this.serviceCharges - this.costs) / this.totalValueEuro) * 100 * 100) / 100 : 0;
  }

  get addressBuilt(): string {
    return `${this.street} ${this.houseNumber} ${this.city} ${this.country}`;
  }

  get assetId(): string | undefined {
    return this.$route.params.assetId;
  }

  get lastUpdate(): number | null {
    if (this.assetId && this.asset) {
      const timestamp = convertUTCToLocalDate(this.asset.updatedDateTime || this.asset.createdDateTime)!;
      return timestamp.getTime();
    }

    return null;
  }

  // Handle save button loading spinner
  get saveLoading(): boolean {
    if (!this.loadingAsset) {
      return false;
    }

    return (this.operationalAsset?.operation === 'updateAsset' || this.operationalAsset?.operation === 'createAsset');
  }

  get publishing(): boolean {
    return this.operationalAsset?.operation === 'handlePublishAssetById' && this.operationalAsset.status === DataContainerStatus.Processing;
  }

  addNewValuation(): void {
    this.valuation = [
      ...this.valuation,
      {
        propertyValue: null,
        purchaseCost: null,
        tax: null,
        createdDateTime: null,
      },
    ];
  }

  // Sets the start and end date to midnight upon selecting it via the datepicker
  setDateToMidnight(key: 'startDateTime' | 'endDateTime', date: Date | null): void {
    if (date) {
      this[key] = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
  }

  /*
  * Checks if the user should be able to save the draft, that requires
  * the name to be defined
  * all errors present should only be due to them missing (i.e. in violation of being required)
  * if it is already published there can't be any invalid entries
  * */
  isAbleToSaveDraft(): boolean {
    if (this.name.length === 0) {
      this.saveButtonTitle = 'Enter a fund name to save draft';
      return false;
    }

    if (!this.form) {
      return false;
    }

    if (this.published) {
      return this.form.flags.valid;
    }

    if (this.form.errors) {
      const isOnlyRequiredViolated = Object.values(this.form.fields).every(
        (field) => {
          if (field.failed) {
            return Object.keys(field.failedRules).every(((failedRuleKey) => failedRuleKey === 'required'));
          }
          return true;
        },
      );
      this.saveButtonTitle = isOnlyRequiredViolated ? '' : 'Invalid input';
      return isOnlyRequiredViolated;
    }
    return true;
  }

  inputFilter(newFile, oldFile, prevent): void {
    // Preventing a non-pdf file to be inserted
    if (newFile && !oldFile && !/\.(pdf)$/i.test(newFile.name)) {
      this.addToastMessage({
        text: 'Only pdf files allowed.',
        type: 'danger',
      });
      prevent();
    } else {
      this.otherChanges = true;
    }
  }

  removeFile(position: number, fileType: string) {
    this[fileType].splice(position, 1);
    this.otherChanges = true;
  }

  changeDividend(event, type, index): void {
    const targetValue = event;
    const year = this.dividendsFormat[index].contents[0];
    const dividends = this.dividendsFormat[index].contents[1];
    if (type === 'year') {
      this.dividendsFormat[index].contents = [targetValue, dividends];
    } else {
      this.dividendsFormat[index].contents = [year, targetValue];
    }
  }

  removeDividendsRow(row: number): void {
    this.dividendsFormat.splice(row, 1);
    this.otherChanges = true;
  }

  addDividendsRow(): void {
    this.dividendsFormat.push({ contents: ['', 0] });
  }

  uploadOrEditImage(formData: FormData, index: number, fileList: any[], type: string): void {
    const file = formData.get('file') as File;
    if (file) {
      // Limiting size by 10MB
      if (file.size < 10000000) {
        this[type] = fileList;
        this[type][index].file = formData.get('file');
        this.otherChanges = true;
      } else {
        fileList.pop();
      }
    }
  }

  beforeRemove(index: number, removeFn: Function, fileList: any[], type: string): void {
    removeFn();
    this.otherChanges = true;
  }

  async submitAsset(): Promise<void> {
    await this.form.validate();

    if (this.deltaValue !== 0) {
      this.addToastMessage({
        text: `The current valuation results in a decimal number of shares. Please increase the latest property value by ${this.deltaValue}€`,
        type: 'danger',
      });

      return;
    }

    if (this.isAbleToSaveDraft()) {
      // For a share product we submit the expected dividend yield. The duration year is not meaningful so we submit a "not applicable" string
      const formattedDividendsFormat = [
        {
          contents: [
            'not applicable',
            this.dividendYield,
          ],
        },
      ];

      // Transforming valutaion fields
      const valuation = this.valuation.map((val): Valuation => ({
        propertyValue: Number(val.propertyValue),
        purchaseCost: Number(val.purchaseCost),
        tax: Number(val.tax),
        createdDateTime: val.createdDateTime ? val.createdDateTime : null,
      }));

      const formAsset = {
        ...this.assetId && { id: this.assetId },
        name: this.name.trim(),
        street: this.street.trim(),
        houseNumber: this.houseNumber.trim(),
        postalCode: this.postalCode.trim(),
        city: this.city.trim(),
        country: this.country.trim(),
        dividendsFormat: formattedDividendsFormat,
        investmentCase: this.investmentCase,
        propertyDetails: this.propertyDetails,
        totalValueEuro: Number(this.totalValueEuro),
        euroMin: Number(this.euroMin),
        sharePrice: this.evaluatedSharePrice,
        valuation,
        emissionCost: Number(this.emissionCost),
        ...this.excessEmissionPerc !== null && {
          excessEmission: {
            percentage: Number(this.excessEmissionPerc),
            threshold: Number(this.excessEmissionThreshold),
          },
        },
        hpi: this.hpi.map(Number),
        grossRent: Number(this.grossRent),
        serviceCharges: Number(this.serviceCharges),
        costs: Number(this.costs),
        premium: this.premium,
        published: this.published,
        images: this.images.map((img): any => img.file),
        floorPlanImages: this.floorPlanImages.map((img): any => img.file),
        prospectus: this.prospectus.map((prospectus): any => prospectus.file),
        brochure: this.brochure.map((prospectus): any => prospectus.file),
        startDateTime: this.startDateTime && convertLocalDateToUTC(this.startDateTime, true)?.getTime(),
        endDateTime: this.endDateTime && convertLocalDateToUTC(this.endDateTime, true)?.getTime(),
        returnsAfterEnd: Number(this.returnsAfterEnd),
        ...(this.fixedDividends !== null && { fixedDividends: this.fixedDividends }),
      };

      if (!this.assetId) {
        await this.createAsset({ asset: formAsset });
      } else {
        await this.updateAsset({ asset: formAsset });
      }
    }
  }

  async publish(): Promise<void> {
    if (!this.assetId) {
      this.addToastMessage({
        text: 'Please, save the asset first.',
        type: 'danger',
      });

      // Setting back to false with some delay due to the visual effect not being applied if insta change twice
      setTimeout(() => {
        this.published = false;
      }, 200);
      return;
    }
    if (this.published) {
      this.addToastMessage({
        text: 'The asset was published!',
        type: 'success',
      });
    } else {
      this.addToastMessage({
        text: 'The asset was unpublished!',
        type: 'success',
      });
    }

    this.handlePublishAssetById({ assetId: this.assetId, published: this.published });
  }
}
