















































































































  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
  import { Bold, Italic, Heading, Link } from 'tiptap-extensions';
  import debounce from 'lodash.debounce';

  @Component({
    components: {
      ValidationProvider,
      FormInvalidMessage,
      EditorMenuBubble,
      EditorContent,
      EditorMenuBar,
    },
  })
  /** Class for the FormEditor
   * @prop  rules Only supports required */
  export default class FormEditor extends Vue {
    @Prop() name!: string;
    @Prop() rules!: string;
    @Prop() label!: string;
    @Prop({ default: '' }) value!: string;
    @Prop({ default: true }) bails!: boolean;

    @Ref('validator') readonly validator!: any;

    editor: Editor = new Editor({
      extensions: [
        new Bold(),
        new Italic(),
        new Heading({ levels: [4, 5, 6] }),
        new Link(),
      ],
      content: this.value,
      onUpdate: debounce(this.updateValue, 400, {
        leading: true,
        trailing: true,
      }),
    });

    linkUrl = null;
    linkMenuIsActive = false;
    isEditing = false;

    get isRequired(): boolean {
      return this.rules.includes('required');
    }

    get currentValue(): string | null {
      return this.editor?.getJSON()?.content?.find((chunk) => chunk?.content?.[0]?.text)?.content?.[0]?.text ?? null;
    }

// create two-way databinding
    @Watch('value', { immediate: false }) // downwards
    onChangeToTheValue(newValue) {
      if (!this.isEditing) { // if the change was caused by this FormEditor itself don't overwrite it again
        this.editor.setContent(this.value);
        const isNull = ((this.currentValue === null) && this.isRequired);
        this.validator.setFlags({
          changed: false,
          dirty: false,
          failed: isNull,
          invalid: isNull,
          passed: !isNull,
          pending: false,
          pristine: true,
          required: this.isRequired,
          touched: false,
          untouched: true,
          valid: !isNull,
          validated: false,
        });
      }
      this.isEditing = false;
    }

    beforeDestroy(): void {
      this.editor.destroy();
    }

    updateValue(newValue: any): void {
      this.$emit('input', newValue.getHTML()); // upwards
      this.isEditing = true;
      this.$nextTick(() => {
        this.validator.validate();
      });
    }

    showLinkMenu(attrs): void {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.linkInput.focus();
      });
    }

    hideLinkMenu(): void {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    }

    setLinkUrl(command, url): void {
      command({ href: url });
      this.hideLinkMenu();
    }
  }
