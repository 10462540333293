























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { GetCollectionParams } from '@/store/actions';
import { ErrorVertebra } from '@/store/utils/skeleton';
import Modal from '@/components/common/Modal.vue';
import { Dividend } from '@/models/assets/Dividends';
import { ValidationObserver } from 'vee-validate';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';

@Component({
  components: {
    Modal,
    FormDatePicker,
    FormInput,
    ValidationObserver,
  },
})
export default class ModifyDividendModal extends Vue {
  @Prop() assetId!: string;
  @Prop() valuation!: { propertyValue: number, purchaseCost: number, tax: number };

  @Action(addToastMessage) addToastMessage!: Function;
  @Action modifyLastValuation!: ({ assetId, valuation }:
    { assetId: string, valuation: { propertyValue: number, purchaseCost: number, tax: number } }) => void;

  @StateClass('asset') stateAsset!: State['asset'];

  // Valuation fields
  propertyValue: number = this.valuation.propertyValue;
  purchaseCost: number = this.valuation.purchaseCost;
  tax: number = this.valuation.tax;
  FormIcons = FormIcons;

  @Watch('stateDividend.status')
  onstateAssetChange(dividendStatus: DataContainerStatus): void {
    // Error
    if (dividendStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateAsset?.error,
        type: 'danger',
      });
    }

    // Success
    if (dividendStatus === DataContainerStatus.Success) {
      // Notification
      this.addToastMessage({
        text: 'The dividends have been successfully updated.',
        type: 'success',
      });
    }

    if (dividendStatus !== DataContainerStatus.Processing) {
      this.$emit('close');
    }
  }

  get isProcessing(): boolean {
    return this.stateAsset?.status === DataContainerStatus.Processing;
  }

  submitValuationUpdate(): void {
    this.modifyLastValuation({
      assetId: this.assetId,
      valuation: {
        propertyValue: Number(this.propertyValue),
        purchaseCost: Number(this.purchaseCost),
        tax: Number(this.tax),
      },
    });
  }
}
