









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { GetCollectionParams } from '@/store/actions';
import { ErrorVertebra } from '@/store/utils/skeleton';
import Modal from '@/components/common/Modal.vue';
import { Dividend } from '@/models/assets/Dividends';
import { ValidationObserver } from 'vee-validate';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';

@Component({
  components: {
    Modal,
    FormDatePicker,
    FormInput,
    ValidationObserver,
  },
})
export default class ModifyDividendModal extends Vue {
  @Prop() dividend!: Dividend;
  @Prop() assetId!: string;

  @Action(addToastMessage) addToastMessage!: Function;
  @Action updateDividend!: ({ amount, assetId, dividendId, period }:
    { amount: number, assetId: string, dividendId: string, period: Date }) => Promise<void>;

  @StateClass('dividend') stateDividend!: State['dividend'];

  newAmount = this.dividend.amount;
  newPeriod = this.dividend.period.toDate();
  FormIcons = FormIcons;

  @Watch('stateDividend.status')
  onstateAssetChange(dividendStatus: DataContainerStatus): void {
    if (dividendStatus !== DataContainerStatus.Processing) {
      this.$emit('close');
    }
  }

  get isProcessing(): boolean {
    return this.stateDividend?.status === DataContainerStatus.Processing;
  }
}
