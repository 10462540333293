





























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { to } from 'await-to-js';
import { bloqifyFirestore } from '@/boot/firebase';
import { State } from '@/models/State';
import { Asset } from '@/models/assets/Asset';
import { Dividend } from '@/models/assets/Dividends';
import { DataContainerStatus } from '@/models/Common';
import { ValidationObserver } from 'vee-validate';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import ModifyDividendModal from '@/components/assets/ModifyDividendModal.vue';
import ModifyValuationModal from '@/components/assets/ModifyValuationModal.vue';

@Component({
  components: {
    ModifyDividendModal,
    ModifyValuationModal,
    FormDatePicker,
    FormInput,
    ValidationObserver,
  },
})
export default class AssetFinancials extends Vue {
  @Action getAssetById!: ({ id }: { id: string }) => void;
  @Action addDividend!: ({ assetId, amount, period }: { assetId: string, amount: number, period: Date }) => void;
  @Action addValuation!: ({ assetId, valuation }:
    { assetId: string, valuation: { propertyValue: number, purchaseCost: number, tax: number } }) => void;
  @Action(addToastMessage) addToastMessage!: Function;
  @Action bindFirestoreReferences!: Function;
  @Action unbindFirestoreReferences!: Function;

  @StateClass('boundAsset') asset!: Asset;
  @StateClass('boundDividends') boundDividends!: Dividend[];
  @StateClass('asset') stateAsset!: State['asset'];
  @StateClass('dividend') stateDividend!: State['dividend'];

  // Dividend fields
  amount: number | null = null;
  period: Date | null = null;
  // Valuation fields
  propertyValue: number | null = null;
  purchaseCost: number | null = null;
  tax: number | null = null;

  loadingPromise: Promise<any> = Promise.resolve();
  showModifyDividendModal: boolean = false;
  showModifyValuationModal: boolean = false;
  selectedDividend: Dividend | null = null;
  FormIcons = FormIcons;

  dividendOptions = {
    headings: {
      period: 'Period',
      amount: 'Dividend amount',
      modify: '',
    },
    filterable: ['period', 'amount'],
    columnsClasses: {
      period: 'list__filter--name align-middle',
      amount: 'list__filter--sold align-middle',
      modify: 'list__filter--modify align-middle',
    },
    skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
  };

  valuationOptions = {
    headings: {
      propertyValue: 'Property value',
      purchaseCost: 'Purchase Cost',
      tax: 'Tax',
      createdDateTime: 'Created At',
      modify: '',
    },
    filterable: ['propertyValue', 'purchaseCost', 'tax', 'createdDateTime'],
    columnsClasses: {
      propertyValue: 'list__filter--name align-middle',
      purchaseCost: 'list__filter--sold align-middle',
      tax: 'list__filter--sold align-middle',
      createdDateTime: 'list__filter--sold align-middle',
      modify: 'list__filter--modify align-middle',
    },
    skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
  };

  @Watch('stateAsset.status')
  onNewAssetError(newStatus: DataContainerStatus): void {
    if (newStatus === DataContainerStatus.Success && this.stateAsset!.operation === 'addValuation') {
      this.addToastMessage({
        text: 'Valuation successfully added',
        type: 'success',
      });
    } else if (newStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateDividend!.error || 'There was a problem with adding/updating valuation',
        type: 'danger',
      });
    }
  }

  @Watch('stateDividend.status')
  onNewDividendError(newStatus: DataContainerStatus): void {
    if (newStatus === DataContainerStatus.Success) {
      this.addToastMessage({
        text: `Dividend successfully ${this.stateDividend!.operation === 'addDividend' ? 'added' : 'modified'}`,
        type: 'success',
      });
    } else if (newStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateDividend!.error || 'There was a problem with adding/updating dividends',
        type: 'danger',
      });
    }
  }

  @Watch('assetId', { immediate: true })
  async onNewAsset(newAssetId: string | null): Promise<void> {
    if (newAssetId) {
      this.loadingPromise = this.bindFirestoreReferences([
        {
          name: 'boundAsset',
          ref: bloqifyFirestore.collection('assets').doc(newAssetId),
        },
        {
          name: 'boundDividends',
          ref: bloqifyFirestore.collection('assets').doc(newAssetId).collection('dividends')
            .orderBy('period', 'desc'),
        },
      ]);
    }
  }

  beforeDestroy(): void {
    this.unbindFirestoreReferences([{ name: 'boundDividends' }]);
  }

  get assetId(): string {
    return this.$route.params.assetId;
  }

  get defaultDate(): Date {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }

  get sortedValuation(): any[] {
    const val = [...this.asset.valuation];
    return val.sort((a, b): number => b.createdDateTime.toMillis() - a.createdDateTime.toMillis());
  }

  get dividends(): Dividend[] {
    return this.boundDividends.map((dividend): Dividend => ({ ...dividend, id: dividend.id }));
  }

  openDividendModal(dividend: Dividend): void {
    this.showModifyDividendModal = true;
    this.selectedDividend = dividend;
  }

  async submitDividends(): Promise<void> {
    this.addDividend({ assetId: this.assetId, amount: Number(this.amount), period: this.period as Date });
  }

  async submitEvaluation(): Promise<void> {
    this.addValuation({
      assetId: this.assetId,
      valuation: {
        propertyValue: Number(this.propertyValue),
        purchaseCost: Number(this.purchaseCost),
        tax: Number(this.tax),
      },
    });
  }
}
