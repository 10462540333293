var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validator",class:{'form-group' : true},attrs:{"name":_vm.name,"rules":_vm.rules,"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_c('editor-menu-bar',{class:{'is-invalid': errors[0]},attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar editor__buttons"},[_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.bold() },on:{"click":function($event){$event.preventDefault();return commands.bold($event)}}},[_c('strong',[_vm._v("B")])]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.italic() },on:{"click":function($event){$event.preventDefault();return commands.italic($event)}}},[_c('i',[_vm._v("i")])]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 4 }) },on:{"click":function($event){$event.preventDefault();return commands.heading({ level: 4 })}}},[_vm._v(" h4 ")]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 5 }) },on:{"click":function($event){$event.preventDefault();return commands.heading({ level: 5 })}}},[_vm._v(" h5 ")]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 6 }) },on:{"click":function($event){$event.preventDefault();return commands.heading({ level: 6 })}}},[_vm._v(" h6 ")])])]}}],null,true)}),_c('editor-menu-bubble',{staticClass:"menububble",attrs:{"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"placeholder":"https://","type":"text"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu()},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_c('button',{staticClass:"menububble__button",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, null)}}},[_vm._v(" Remove ")])]):[_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.link() },on:{"click":function($event){$event.preventDefault();_vm.showLinkMenu(getMarkAttrs('link'))}}},[_c('span',[_vm._v(_vm._s(isActive.link() ? 'Update Link' : 'Add Link'))]),_c('img',{attrs:{"src":require("@/assets/icons/link-icon.svg")}})])]],2)]}}],null,true)}),_c('editor-content',{staticClass:"form-control form-control-bg editor__textarea",class:['form-control', 'form-control-bg', 'editor__textarea', errors[0] ? 'is-invalid' : ''],attrs:{"editor":_vm.editor}}),_c('FormInvalidMessage',{attrs:{"errors":errors}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],attrs:{"id":_vm.name,"type":"hidden"},domProps:{"value":(_vm.currentValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentValue=$event.target.value}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }